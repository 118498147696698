<template>
	<v-container fluid class="pa-0">
		<v-toolbar flat dense dark :style="stickyTop" extension-height="4">
			<v-toolbar-title>{{ $t("BTW Aangiften") }} </v-toolbar-title>
			<template #extension v-if="loading">
				<v-progress-linear bottom indeterminate color="white"></v-progress-linear>
			</template>
		</v-toolbar>
		<v-container>
			<v-row justify="center">
				<v-col cols="12" md="10">
					<v-card flat outlined>
						<v-card-title>BTW aangiften </v-card-title>
						<v-data-table disable-sort disable-pagination hide-default-footer :items="vatDeclarations"
							:headers="headers" single-expand show-expand :expanded.sync="expanded"
							v-on:item-expanded="getPdf" item-key="btwaangnr" expand-icon="mdi-file-document-outline">
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<pdf :src="src" class="ma-3" v-if="item.document_usds_id" />
									<p v-else>
										Het document is niet beschikbaar
									</p>
								</td>
							</template>
						</v-data-table>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" md="10">
					<VatBookings />
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import pdf from "vue-pdf";
import caas from "@/services/caas";
import VatBookings from "@/components/VatBookings";
import { useSessionStore } from "@/store/session";
export default {
	name: "VatDeclaration",
	components: { VatBookings, pdf },
	computed: {
		stickyTop() {
			return {
				position: "sticky",
				top: this.$vuetify.application.top + "px",
				zIndex: 1,
			};
		},
	},
	data() {
		return {
			expanded: [],
			loading: false,
			vatDeclarations: [],
			headers: [
				{ text: "Periode", value: "van_periode" },
				{ text: "Jaar", value: "kaljaar" },
				{ text: "Kwartaal", value: "kwartaal" },
				{ text: "Maand", value: "maand" },
				{ text: "", value: "data-table-expand" },
			],
			src: null,
			showPdf: false,
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchVatDeclarations() {
			const sdoName = "boek/objects/dBtwaang.r";
			let params = {
				action: "read",
				fiscalYear: Number(this.sessionStore.fiscalYear),
				nbrOfRows: 99,
				setOpenQuery: "for each btwaang no-lock indexed-reposition",
			};

			this.loading = true;
			const response = await caas.sdo(sdoName, params);
			this.vatDeclarations = response.data.data.RowObject;
			this.loading = false;
		},
		async getPdf(obj) {
			if (obj.value) {
				this.loading = true;
				let params = {
					usds_id: Number(obj.item.document_usds_id),
				};
				caas.rpc("getBLOB", params).then((response) => {
					const binaryString = window.atob(
						response.data.blob
					);
					const len = binaryString.length;
					const bytes = new Uint8Array(len);
					for (let i = 0; i < len; ++i) {
						bytes[i] = binaryString.charCodeAt(i);
					}
					this.src = bytes; //new Blob([bytes], { type: "application/pdf" });
					this.loading = false;
					this.showPdf = true;
				});
			} else {
				this.showPdf = false;
				this.src = null;
			}
		},
	},
	mounted() {
		this.fetchVatDeclarations();
	},
};
</script>
