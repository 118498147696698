<template>
	<v-card flat outlined>
		<v-card-title> BTW boekingen </v-card-title>
		<v-data-table disable-sort disable-pagination hide-default-footer :items="vatBookings" :headers="headers" dense>
		</v-data-table>
	</v-card>
</template>

<script>
import caas from "@/services/caas";
import { useSessionStore } from "@/store/session";
export default {
	name: "VatBookings",
	data() {
		return {
			loading: false,
			vatBookings: [],
			headers: [],
			sessionStore: useSessionStore()
		};
	},
	methods: {
		async fetchVatBookings() {
			let params = {
				fiscalYear: Number(this.sessionStore.fiscalYear),
			};
			this.loading = true;
			const response = await caas.rpc("getVatBookings", params);
			this.vatBookings = response.data.data;
			this.loading = false;
		},
	},
	mounted() {
		this.headers = [{ text: "Rooster", value: "gridId", width: "50px" }];
		this.sessionStore.fiscalPeriods.forEach((period) => {
			let header = {
				text: `Periode ${period.value}`,
				value: `vatAmount${period.value}`,
				align: "end",
			};
			this.headers.push(header);
		});
		this.headers.push({
			text: "Totaal",
			value: "gridTotal",
			align: "end",
		});

		this.fetchVatBookings();
	},
};
</script>
